import ApartmentScene from './pages/ApartmentScene/ApartmentScene';

function App() {
  return (
    <div className="appWrapper">
      <div className="sceneWrapper">
        <ApartmentScene />
      </div>
    </div>
  );
}

export default App;
