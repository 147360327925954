import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Euler, Vector3 } from 'three';

import ApartmentModel from '../../components/ApartmentModel/ApartmentModel';
import {
  CAMERA_DATA,
  DIRECTIONAL_LIGHT_INTENSITY,
  DIRECTIONAL_LIGHT_POSITION,
} from '../../constants/constants';

function ApartmentScene() {
  return (
    <Suspense>
      <Canvas camera={{
        fov: CAMERA_DATA.FOV,
        position: new Vector3(CAMERA_DATA.X, CAMERA_DATA.Y, CAMERA_DATA.Z),
        rotation: new Euler(0, 0, 0),
      }}
      >
        <ApartmentModel />
        <directionalLight
          intensity={DIRECTIONAL_LIGHT_INTENSITY}
          position={DIRECTIONAL_LIGHT_POSITION}
        />
      </Canvas>
    </Suspense>
  );
}

export default ApartmentScene;
